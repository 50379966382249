<template>
  <div class="modal-mask" v-if="show">
    <div class="modal-container rounded">
      <div class="modal-header">
        <h2 class="modal-title fs-5" id="exampleModalLabel" style="font-weight: bold;">
          PRP Claim
        </h2>

        <button type="button" class="btn-close" data-bs-dismiss="modal-mask" aria-label="Close"
          @click="$emit('close')"></button>
      </div>
      <div class="modal-body">
        <div class="row mt-3">
          <div class="col-sm-4 mt-4">
            <label class="form-check-label" for="Group" style="font-weight: bold;">Date of Follow Up *</label>
            <VueDatePicker id="date_of_follow_up" auto-apply v-model="date_of_follow_up" format="dd/MM/yyyy"
              :disabled="loading" :disabled-dates="disableFutureDates"></VueDatePicker>
          </div>
          <div class="col-sm-4 mt-4">
            <label class="form-check-label" for="users" style="font-weight: bold;">Time of Follow Up *</label>
            <VueTimepicker format="HH:mm" v-model="time_of_follow_up" class="time-field" :disabled="loading" />
          </div>
          <div class="col-sm-4 mt-4">
            <label class="form-check-label" for="users" style="font-weight: bold;">Call Duration (in Minutes) *</label>
            <input type="text" class="form-control" id="call_duration" v-model="call_duration" required
              :disabled="loading" />
          </div>
          <div class="col-sm-4 mt-4">
            <label class="form-check-label" for="users" style="font-weight: bold;">PRP Done Date</label>
            <input type="text" class="form-control" :value="formattedPRPDoneDate" disabled />
          </div>

        </div>
      </div>
      <div class="modal-footer mt-5">
        <button class="btn btn-sm btn-primary mt-2" @click="saveData" :disabled="!isFormValid || loading">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-if="!loading">Submit</span>
        </button>
      </div>
    </div>
  </div>
  <!-- Success Modal -->
  <div class="modal-mask" v-if="showSuccessModal">
    <div class="modal-container rounded">
      <div class="modal-header">
      </div>
      <div class="modal-body" style="display: flex;flex-direction: row; justify-content: center;">
        <h2 class="mt-5 mb-5" style="color: darkgreen; font-weight: bold;">{{ message }}</h2>
      </div>
      <div class="modal-footer">
        <button class="btn btn-sm btn-primary" @click="closeSuccessModal">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import 'vue3-timepicker/dist/VueTimepicker.css';
import VueTimepicker from 'vue3-timepicker';
import { format } from 'date-fns';

export default {
  name: "PrpModel",
  props: {
    msg: String,
    show: false,
    exercise_options: [],
    Knee_lead: "",
  },
  components: {
    VueTimepicker,
  },
  computed: {
    isFormValid() {
      return this.time_of_follow_up && this.date_of_follow_up && this.call_duration; // Check if all fields are filled
    },
    formattedPRPDoneDate() {
      if (this.prp_done_date) {
        // Format the date to 'dd-MM-yyyy'
        return format(new Date(this.prp_done_date), 'dd-MM-yyyy');
      }
      return '';
    }
  },
  mounted() {
    this.get_PRP_Data();
  },
  data() {
    return {
      knee_lead: this.$route.params.id,
      date_of_follow_up: null,
      time_of_follow_up: "",
      call_duration: "",
      showSuccessModal: false,
      message: "",
      loading: false,
      prp_done_date: "",

    };
  },
  methods: {

    get_PRP_Data: async function () {
      await axios
        .get("knee_lead.api.get_single_knee_lead", {
          params: {
            mobile: this.$route.params.id,
          },
        })
        .then((response) => {
          for (let x in response.data.data) {
            this.prp_done_date =
              response.data.data[x].prp_done_date;
          }
        })
        .catch((error) => { });
    },

    convertCallDurationToSeconds() {
      const durationInMinutes = parseFloat(this.call_duration);
      if (!isNaN(durationInMinutes)) {
        return durationInMinutes * 60; // Convert minutes to seconds
      }
      return 0; // If input is not a number, default to 0 seconds
    },

    disableFutureDates(date) {
      const today = new Date(); // Current date with time
      const selectedDate = new Date(date); // Date from the date picker

      // Use only the date part (ignore time) for both today's date and the selected date
      today.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);

      // Disable future dates by comparing date only
      return selectedDate > today;
    },

    formatTimeForAPI(time) {
      if (!time) {
        return '';
      }
      const [hours, minutes] = time.split(':');
      return `${hours}:${minutes}:00`;
    },

    saveData() {
      if (!this.isFormValid) return;
      this.loading = true;
      const formattedDate = this.date_of_follow_up ? new Date(this.date_of_follow_up).toISOString().split('T')[0] : null;
      const formattedTime = this.formatTimeForAPI(this.time_of_follow_up);
      const callDurationInSeconds = this.convertCallDurationToSeconds();
      const requestData = {
        knee_lead: this.knee_lead,
        date_of_follow_up: formattedDate,
        time_of_follow_up: formattedTime,
        call_duration: callDurationInSeconds,
      };
      console.log("AddData", requestData);
      axios
        .post("knee_lead.claim.create_prp_done_claim", requestData)
        .then(response => {
          if (response.data.status_code === 200) {
            this.showSuccessModal = true;
            this.message = response.data.message;
          }
        })
        .catch(error => {
          console.error("Error saving data:", error);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .finally(() => {
          this.loading = false; // Reset loading state after the request completes
        });
    },

    closeSuccessModal() {
      this.showSuccessModal = false;
      // Optionally reload the page after closing success modal
      window.location.reload();
    }
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  inset: 0;
  background: rgba(8, 0, 8, 0.4);
  z-index: 2;
  display: grid;
  place-items: center;
}

.modal-container {
  background: white;
  padding: 1rem;
  width: 80vw;
  max-width: 50%;
}
</style>