<template>
    <div>
        <div v-if="showModal" class="modal" @click.self="closeModal">
            <div class="modal-content" style="color: #2E8B57;">
                <span class="close" @click="closeModal">&times;</span>
                <h3 style="font-weight: bold;">Changes saved successfully!</h3>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="row">
                        <div class="col">
                            <div>
                                <label class="form-select-label kx-label-secondary" for="State">OPD
                                    Location</label>
                                <select class="form-select" aria-label="State" v-model="opd_location">
                                    <option v-for="option in opd_location_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label for="opd_request_date_time" class="form-label kx-label-secondary">
                                    OPD Request Date and Time</label>
                                <VueDatePicker id="opd_request_date_time" v-model="opd_request_date_time"
                                    format="dd/MM/yyyy H:mm"></VueDatePicker>
                            </div>

                            <label for="opd_date" class="form-label kx-label-secondary">
                                OPD Consultation Done on</label>
                            <VueDatePicker id="opd_date" v-model="opd_date" format="dd/MM/yyyy"></VueDatePicker>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="opd_remarks " class="kx-label-secondary">Remark</label>
                                <textarea class="form-control" id="opd_remarks" rows="5" placeholder="Remarks"
                                    v-model="opd_remarks"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="row">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Surgery</th>
                                    <th scope="col" colspan="2">Advice</th>
                                    <th scope="col" colspan="2">Willing</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <td style="color: red">Right</td>
                                    <td style="color: blue">Left</td>
                                    <td style="color: red">Right</td>
                                    <td style="color: blue">Left</td>
                                </tr>
                                <tr>
                                    <th scope="row">SVF</th>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_svf_right" true-value="1" false-value="0"
                                                id="opd_advise_svf_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_svf_left" true-value="1" false-value="0"
                                                id="opd_advise_svf_left" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_svf_right" true-value="1"
                                                false-value="0" id="opd_patient_willingness_svf_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_svf_left" true-value="1"
                                                false-value="0" id="opd_patient_willingness_svf_left" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">PFO</th>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_pfo_right" true-value="1" false-value="0"
                                                id="opd_advise_pfo_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_pfo_left" true-value="1" false-value="0"
                                                id="opd_advise_pfo_left" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_pfo_right" true-value="1"
                                                false-value="0" id="opd_patient_willingness_pfo_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_pfo_left" true-value="1"
                                                false-value="0" id="opd_patient_willingness_pfo_left" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">HTO</th>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_hto_right" true-value="1" false-value="0"
                                                id="opd_advise_hto_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_hto_left" true-value="1" false-value="0"
                                                id="opd_advise_hto_left" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_hto_right" true-value="1"
                                                false-value="0" id="opd_patient_willingness_hto_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_hto_left" true-value="1"
                                                false-value="0" id="opd_patient_willingness_hto_left" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">DFO</th>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_dfo_right" true-value="1" false-value="0"
                                                id="opd_advise_dfo_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_dfo_left" true-value="1" false-value="0"
                                                id="opd_advise_dfo_left" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_dfo_right" true-value="1"
                                                false-value="0" id="opd_patient_willingness_dfo_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_dfo_left" true-value="1"
                                                false-value="0" id="opd_patient_willingness_dfo_left" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Arthroscopy</th>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_arthro_right" true-value="1" false-value="0"
                                                id="opd_advise_arthro_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_arthro_left" true-value="1" false-value="0"
                                                id="opd_advise_arthro_left" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_arthro_right" true-value="1"
                                                false-value="0" id="opd_patient_willingness_arthro_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_arthro_left" true-value="1"
                                                false-value="0" id="opd_patient_willingness_arthro_left" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">TKR</th>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_tkr_right" true-value="1" false-value="0"
                                                id="opd_advise_tkr_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_tkr_left" true-value="1" false-value="0"
                                                id="opd_advise_tkr_left" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_tkr_right" true-value="1"
                                                false-value="0" id="opd_patient_willingness_tkr_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_tkr_left" true-value="1"
                                                false-value="0" id="opd_patient_willingness_tkr_left" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">THR</th>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_thr_right" true-value="1" false-value="0"
                                                id="opd_advise_thr_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_thr_left" true-value="1" false-value="0"
                                                id="opd_advise_thr_left" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_thr_right" true-value="1"
                                                false-value="0" id="opd_patient_willingness_thr_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_thr_left" true-value="1"
                                                false-value="0" id="opd_patient_willingness_thr_left" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">PRP</th>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_prp_right" true-value="1" false-value="0"
                                                id="opd_advise_prp_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_prp_left" true-value="1" false-value="0"
                                                id="opd_advise_prp_left" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_prp_right" true-value="1"
                                                false-value="0" id="opd_patient_willingness_prp_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_prp_left" true-value="1"
                                                false-value="0" id="opd_patient_willingness_prp_left" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Other</th>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_other_right" true-value="1" false-value="0"
                                                id="opd_advise_other_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_advise_other_left" true-value="1" false-value="0"
                                                id="opd_advise_other_left" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_other_right" true-value="1"
                                                false-value="0" id="opd_patient_willingness_other_right" />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input class="form-check-input" type="checkbox" value=""
                                                v-model="opd_patient_willingness_other_left" true-value="1"
                                                false-value="0" id="opd_patient_willingness_other_left" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="mt-4">
                    <button class="btn btn-primary" @click="saveChanges" style="background-color: #007bff;"
                        @mouseover="changeColor" @mouseleave="resetColor">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "OpdConsultationTab",
    props: {
        msg: String,
    },
    mounted() {
        this.get_opd_consultation();
        this.get_opd_list()
    },
    data() {
        return {
            name: "",
            opd_date: null,
            opd_request_date_time: null,
            opd_by: null,
            opd_follow_up_date: null,
            opd_exercise_shared: null,
            opd_next_follow_up_date: null,
            opd_advise_svf_left: null,
            opd_advise_pfo_left: null,
            opd_advise_hto_left: null,
            opd_advise_dfo_left: null,
            opd_advise_arthro_left: null,
            opd_advise_tkr_left: null,
            opd_advise_thr_left: null,
            opd_advise_prp_left:null,
            opd_advise_svf_right: null,
            opd_advise_pfo_right: null,
            opd_advise_hto_right: null,
            opd_advise_dfo_right: null,
            opd_advise_arthro_right: null,
            opd_advise_tkr_right: null,
            opd_advise_thr_right: null,
            opd_advise_prp_right:null,
            opd_advise_other_left:null,
            opd_advise_other_right:null,
            opd_patient_willingness_svf_left: null,
            opd_patient_willingness_pfo_left: null,
            opd_patient_willingness_hto_left: null,
            opd_patient_willingness_dfo_left: null,
            opd_patient_willingness_arthro_left: null,
            opd_patient_willingness_tkr_left: null,
            opd_patient_willingness_thr_left: null,
            opd_patient_willingness_prp_left:null,
            opd_patient_willingness_svf_right: null,
            opd_patient_willingness_pfo_right: null,
            opd_patient_willingness_hto_right: null,
            opd_patient_willingness_dfo_right: null,
            opd_patient_willingness_arthro_right: null,
            opd_patient_willingness_tkr_right: null,
            opd_patient_willingness_thr_right: null,
            opd_patient_willingness_prp_right:null,
            opd_patient_willingness_other_left:null,
            opd_patient_willingness_other_right:null,
            opd_remarks: "",
            opd_location_options: [],
            opd_location: "",
            showModal: false,
        };
    },
    methods: {

        // list of opd location 
        get_opd_list: async function () {
            await axios
                .get("knee_lead.api.get_center_list")
                .then((response) => {
                    var res_data = response.data.data[0];
                    for (let x in res_data) {
                        this.opd_location_options.push(res_data[x].name);
                    }
                })
                .catch((error) => { });
        },


        get_opd_consultation: async function () {
            await axios
                .get("knee_lead.api.get_opd_consultation_by_knee_lead", {
                    params: {
                        mobile: this.$route.params.id,
                    },
                })
                .then((response) => {
                    for (let x in response.data.data) {
                        this.name = response.data.data[x].name;
                        this.opd_location = response.data.data[x].opd_location;
                        this.opd_date = response.data.data[x].opd_date;
                        this.opd_request_date_time =
                            response.data.data[x].opd_request_date_time;
                        this.opd_by = response.data.data[x].opd_by;
                        this.opd_follow_up_date = response.data.data[x].opd_follow_up_date;
                        this.opd_exercise_shared =
                            response.data.data[x].opd_exercise_shared;
                        this.opd_next_follow_up_date =
                            response.data.data[x].opd_next_follow_up_date;
                        this.opd_advise_svf_left =
                            response.data.data[x].opd_advise_svf_left;
                        this.opd_advise_pfo_left =
                            response.data.data[x].opd_advise_pfo_left;
                        this.opd_advise_hto_left =
                            response.data.data[x].opd_advise_hto_left;
                        this.opd_advise_dfo_left =
                            response.data.data[x].opd_advise_dfo_left;
                        this.opd_advise_arthro_left =
                            response.data.data[x].opd_advise_arthro_left;
                        this.opd_advise_tkr_left =
                            response.data.data[x].opd_advise_tkr_left;
                        this.opd_advise_thr_left =
                            response.data.data[x].opd_advise_thr_left;
                            this.opd_advise_prp_left =
                            response.data.data[x].opd_advise_prp_left;
                        this.opd_advise_svf_right =
                            response.data.data[x].opd_advise_svf_right;
                        this.opd_advise_pfo_right =
                            response.data.data[x].opd_advise_pfo_right;
                        this.opd_advise_hto_right =
                            response.data.data[x].opd_advise_hto_right;
                        this.opd_advise_dfo_right =
                            response.data.data[x].opd_advise_dfo_right;
                        this.opd_advise_arthro_right =
                            response.data.data[x].opd_advise_arthro_right;
                        this.opd_advise_tkr_right =
                            response.data.data[x].opd_advise_tkr_right;
                        this.opd_advise_thr_right =
                            response.data.data[x].opd_advise_thr_right;
                            this.opd_advise_prp_right =
                            response.data.data[x].opd_advise_prp_right;
                        this.opd_patient_willingness_svf_left =
                            response.data.data[x].opd_patient_willingness_svf_left;
                        this.opd_patient_willingness_pfo_left =
                            response.data.data[x].opd_patient_willingness_pfo_left;
                        this.opd_patient_willingness_hto_left =
                            response.data.data[x].opd_patient_willingness_hto_left;
                        this.opd_patient_willingness_dfo_left =
                            response.data.data[x].opd_patient_willingness_dfo_left;
                        this.opd_patient_willingness_arthro_left =
                            response.data.data[x].opd_patient_willingness_arthro_left;
                        this.opd_patient_willingness_tkr_left =
                            response.data.data[x].opd_patient_willingness_tkr_left;
                            this.opd_patient_willingness_prp_left =
                            response.data.data[x].opd_patient_willingness_prp_left;
                        this.opd_patient_willingness_thr_left =
                            response.data.data[x].opd_patient_willingness_thr_left;
                        this.opd_patient_willingness_svf_right =
                            response.data.data[x].opd_patient_willingness_svf_right;
                        this.opd_patient_willingness_pfo_right =
                            response.data.data[x].opd_patient_willingness_pfo_right;
                        this.opd_patient_willingness_hto_right =
                            response.data.data[x].opd_patient_willingness_hto_right;
                        this.opd_patient_willingness_dfo_right =
                            response.data.data[x].opd_patient_willingness_dfo_right;
                        this.opd_patient_willingness_arthro_right =
                            response.data.data[x].opd_patient_willingness_arthro_right;
                        this.opd_patient_willingness_tkr_right =
                            response.data.data[x].opd_patient_willingness_tkr_right;
                        this.opd_patient_willingness_thr_right =
                            response.data.data[x].opd_patient_willingness_thr_right;
                        this.opd_remarks = response.data.data[x].opd_remarks;
                        this.opd_patient_willingness_prp_right =
                            response.data.data[x].opd_patient_willingness_prp_right;
                            this.opd_advise_other_left = response.data.data[x].opd_advise_other_left;
                            this.opd_advise_other_right = response.data.data[x].opd_advise_other_right;
                            this.opd_patient_willingness_other_left = response.data.data[x].opd_patient_willingness_other_left;
                            this.opd_patient_willingness_other_right = response.data.data[x].opd_patient_willingness_other_right;

                        // this.opd_date = date(response.data.data[x].opd_date);
                        // console.log("opd_date", this.opd_date, response.data.data)
                    }
                })
                .catch((error) => { });
        },

        formatDate(date) {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const hours = String(formattedDate.getHours()).padStart(2, '0');
            const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
            const seconds = String(formattedDate.getSeconds()).padStart(2, '0');
            const milliseconds = String(formattedDate.getMilliseconds()).padStart(3, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
        },

        saveChanges: async function () {
            try {
                let updatedFields = {
                    name: this.$route.params.id,
                    opd_request_date_time: null,
                    opd_date: null,
                    opd_advise_svf_left: this.opd_advise_svf_left,
                    opd_advise_pfo_left: this.opd_advise_pfo_left,
                    opd_advise_hto_left: this.opd_advise_hto_left,
                    opd_advise_dfo_left: this.opd_advise_dfo_left,
                    opd_advise_arthro_left: this.opd_advise_arthro_left,
                    opd_advise_tkr_left: this.opd_advise_tkr_left,
                    opd_advise_thr_left: this.opd_advise_thr_left,
                    opd_advise_prp_left:this.opd_advise_prp_left,
                    opd_advise_svf_right: this.opd_advise_svf_right,
                    opd_advise_pfo_right: this.opd_advise_pfo_right,
                    opd_advise_hto_right: this.opd_advise_hto_right,
                    opd_advise_dfo_right: this.opd_advise_dfo_right,
                    opd_advise_arthro_right: this.opd_advise_arthro_right,
                    opd_advise_tkr_right: this.opd_advise_tkr_right,
                    opd_advise_thr_right: this.opd_advise_thr_right,
                    opd_advise_prp_right:this.opd_advise_prp_right,
                    opd_patient_willingness_svf_left: this.opd_patient_willingness_svf_left,
                    opd_patient_willingness_pfo_left: this.opd_patient_willingness_pfo_left,
                    opd_patient_willingness_hto_left: this.opd_patient_willingness_hto_left,
                    opd_patient_willingness_dfo_left: this.opd_patient_willingness_dfo_left,
                    opd_patient_willingness_arthro_left: this.opd_patient_willingness_arthro_left,
                    opd_patient_willingness_tkr_left: this.opd_patient_willingness_tkr_left,
                    opd_patient_willingness_thr_left: this.opd_patient_willingness_thr_left,
                    opd_patient_willingness_prp_left:this.opd_patient_willingness_prp_left,
                    opd_patient_willingness_svf_right: this.opd_patient_willingness_svf_right,
                    opd_patient_willingness_pfo_right: this.opd_patient_willingness_pfo_right,
                    opd_patient_willingness_hto_right: this.opd_patient_willingness_hto_right,
                    opd_patient_willingness_dfo_right: this.opd_patient_willingness_dfo_right,
                    opd_patient_willingness_arthro_right: this.opd_patient_willingness_arthro_right,
                    opd_patient_willingness_tkr_right: this.opd_patient_willingness_tkr_right,
                    opd_patient_willingness_thr_right: this.opd_patient_willingness_thr_right,
                    opd_patient_willingness_prp_right:this.opd_patient_willingness_prp_right,
                    opd_patient_willingness_other_left:this.opd_patient_willingness_other_left,
                    opd_patient_willingness_other_right:this.opd_patient_willingness_other_right,
                    opd_advise_other_right:this.opd_advise_other_right,
                    opd_advise_other_left:this.opd_advise_other_left,
                    opd_remarks: this.opd_remarks,
                    opd_location: this.opd_location
                };

                if (this.opd_request_date_time) {
                    const formattedDate = new Date(this.opd_request_date_time).toISOString();
                    updatedFields.opd_request_date_time = this.formatDate(formattedDate);
                }
                if (this.opd_date) {
                    const formattedDate = new Date(this.opd_date).toISOString();
                    updatedFields.opd_date = this.formatDate(formattedDate);
                }
                await this.update_fields(updatedFields);
                this.showModal = true;
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            } catch (error) {
                console.error('Error saving changes:', error);
            }
        },

        update_fields: async function (updatedFields) {
            try {
                const response = await axios.post("knee_lead.knee_lead_details.update_knee_lead_opd_consultation_tab", updatedFields);
                this.get_opd_consultation();
            } catch (error) {
                console.error('Error updating fields:', error);
                throw error;
            }
        },
        closeModal() {
            this.showModal = false;
        },
    },

};
</script>

<style scoped lang="scss">
.modal {
    /* Styles for modal overlay */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    /* Styles for modal content */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 50%;
    width: 100%;
    position: relative;
    /* Ensure the close icon stays in the modal */
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.close:hover {
    color: red;
}
</style>
